import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_v = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - V'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>V</h2>
     <LatQuotesIntro />
    
<p><b>Verba chartarum fortius accipiuntur contra preferentem</b> - The words of deeds are accepted more strongly against the person offering them.</p>
<p><b>Verba debent intelligi cum effectu</b> - Words ought to be understood with effect.</p>
<p><b>Verba intentioni, non e contra, debent inservire</b> - Words ought to serve the intention, not the reverse.</p>
<p><b>Verbatim</b> - Word by word, exactly.</p>
<p><b>Vi et armis</b> - With the force and arms.</p>
<p><b>Via antiqua via est tuta</b> - The old way is the safe way.</p>
<p><b>Vice versa</b> - The other way around.</p>
<p><b>Vide</b> - See.</p>
<p><b>Vigilantibus non dormientibus jura subveniunt</b> - The laws serve the vigilant, not those who sleep.</p>
<p><b>Vir et uxor consentur in lege una persona</b> - A husband and wife are regarded in law as one person.</p>
<p><b>Visitationem commendamus</b> - We recommend a visitation.</p>
<p><b>Volens</b> - Willing.</p>
<p><b>Volenti non fit injuria</b> - An injury is not done to one consenting to it.</p>
<p><b>Voluntas in delictis non exitus spectatur</b> - In offences the intent and not the result is looked at.</p>
<p><b>Voluntas reputatur pro facto</b> - The will is taken for the deed.</p>


   </Layout>
  )
}

export default LatQuotes_v
